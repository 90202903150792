interface FeatureFlagType {
  companyMilestones: boolean;
  companyReviews: boolean;
  hotjar: boolean;
  userback: boolean;
  datadog: boolean;
  blogDiscussions: boolean;
  heroVideo: boolean;
  bustAPICache: boolean;
  pricingEnabled: boolean;
  accessiBe: boolean;
  cookieBanner: boolean;
}

export const FeatureFlags: FeatureFlagType = {
  companyMilestones: !!process.env.EXPERIMENTAL_COMPANYMILESTONES,
  companyReviews: !!process.env.EXPERIMENTAL_COMPANYREVIEWS,
  hotjar: process.env.EXPERIMENTAL_HOTJAR === 'true' ? true : false,
  userback: process.env.EXPERIMENTAL_USERBACK === 'true' ? true : false,
  accessiBe: process.env.EXPERIMENTAL_ACCESSIBE === 'true' ? true : false,
  cookieBanner: process.env.EXPERIMENTAL_COOKIEBANNER === 'true' ? true : false,
  datadog: process.env.EXPERIMENTAL_DATADOG === 'true' ? true : false,
  blogDiscussions: !!process.env.EXPERIMENTAL_BLOGDISCUSSIONS,
  bustAPICache: process.env.BUST_API_CACHE === 'true' ? true : false,
  heroVideo:
    process.env?.NEXT_PUBLIC_EXPERIMENTAL_HERO_VIDEO === 'true' ? true : false,
  pricingEnabled: process.env.NEXT_PUBLIC_PRICING_ENABLED !== 'false',
};
