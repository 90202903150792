import React from 'react';
import { MdSearch } from 'react-icons/md';
import { useStore } from 'zustand';
import { APIProvider } from '@vis.gl/react-google-maps';
import { useMapStore } from 'stores/map/mapStore';
import { stylesSelect } from 'utils';
import { MAP_RADIAL_OPTIONS } from 'utils/constants';
import { MapPlusSidebar } from './component/map-plus-sidebar';
import InputSelect from '../select/InputSelect';
import { Icon } from '../icons';
import { PlaceAutocompleteCustom } from './component/autocomplete-custom';

const MapComponentStores = () => {
  const API_KEY = process.env.API_KEY_GOOGLEMAPS as string;
  const {
    distanceOptions,
    yourLocation,
    getSelectedDistance,
    setSelectedDistance,
    setYourLocation,
    setSelectedKey,
    hasLoaded,
  } = useStore(useMapStore, (state) => ({
    getSelectedDistance: state.getSelectedDistance,
    setSelectedDistance: state.setSelectedDistance,
    distanceOptions: state.distanceOptions,
    yourLocation: state.yourLocation,
    setYourLocation: state.setYourLocation,
    setSelectedKey: state.setSelectedKey,
    hasLoaded: state.hasLoaded,
  }));

  const setMyposition = () => {
    setYourLocation(yourLocation.lat, yourLocation.lng);
    setSelectedKey(null);
  };

  return (
    <APIProvider apiKey={API_KEY} libraries={['places', 'geometry', 'marker']}>
      <div className='w-full'>
        <div className='flex flex-row items-center gap-3 px-6'>
          <div className='flex w-full rounded-full shadow-sm'>
            <span className='inline-flex min-w-fit items-center rounded-s-full border border-e-0 pl-4 pr-2 text-sm'>
              {hasLoaded.initialLoad ? (
                <div
                  className='inline-block h-4 w-4 animate-spin rounded-full border-[3px] border-current border-t-transparent text-gray-500'
                  role='status'
                  aria-label='loading'
                >
                  <span className='sr-only'>Loading...</span>
                </div>
              ) : (
                <Icon icon={MdSearch} className={`h-5 w-5 text-gray-500`} />
              )}
            </span>
            <div className='relative flex w-full items-center'>
              <PlaceAutocompleteCustom />
            </div>

            <div className='hs-tooltip inline-block'>
              <button
                type='button'
                className={`hs-tooltip-toggle inline-flex h-[2.5rem] w-[2.875rem] flex-shrink-0 items-center justify-center gap-x-2 rounded-e-full border border-s-0 text-sm font-semibold disabled:pointer-events-none disabled:opacity-50 dark:focus:outline-none dark:focus:ring-1  dark:focus:ring-primary`}
                onClick={() => setMyposition()}
              >
                <span
                  className='hs-tooltip-content hs-tooltip-shown:visible hs-tooltip-shown:opacity-100 invisible absolute z-10 inline-block rounded bg-gray-900 px-2 py-1 text-xs font-medium text-white opacity-0 shadow-sm transition-opacity dark:bg-slate-700'
                  role='tooltip'
                >
                  Your location
                </span>
                <svg
                  width='20'
                  height='20'
                  viewBox='0 0 20 20'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <g clipPath='url(#clip0_1224_22715)'>
                    <path
                      d='M8.99916 14.2444C11.895 14.2444 14.2425 11.8969 14.2425 9.00112C14.2425 6.10532 11.895 3.75781 8.99916 3.75781C6.10337 3.75781 3.75586 6.10532 3.75586 9.00112C3.75586 11.8969 6.10337 14.2444 8.99916 14.2444Z'
                      stroke='#1C8EFF'
                      strokeWidth='1.5'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                    <path
                      d='M8.9986 9.58315C9.32036 9.58315 9.58119 9.32231 9.58119 9.00056C9.58119 8.67881 9.32036 8.41797 8.9986 8.41797C8.67685 8.41797 8.41602 8.67881 8.41602 9.00056C8.41602 9.32231 8.67685 9.58315 8.9986 9.58315Z'
                      stroke='#1C8EFF'
                      strokeWidth='1.5'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                    <path
                      d='M9 3.75614V1.42578'
                      stroke='#1C8EFF'
                      strokeWidth='1.5'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                    <path
                      d='M9 16.5725V14.2422'
                      stroke='#1C8EFF'
                      strokeWidth='1.5'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                    <path
                      d='M14.2422 9H16.5725'
                      stroke='#1C8EFF'
                      strokeWidth='1.5'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                    <path
                      d='M1.42578 9H3.75614'
                      stroke='#1C8EFF'
                      strokeWidth='1.5'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                  </g>
                  <defs>
                    <clipPath id='clip0_1224_22715'>
                      <rect width='18' height='18' fill='white' />
                    </clipPath>
                  </defs>
                </svg>
              </button>
            </div>
          </div>

          <div className='flex w-full max-w-xs items-center gap-x-6 py-3'>
            <span className='w-full max-w-fit'>Radius :</span>
            <InputSelect
              customStyles={stylesSelect}
              value={distanceOptions.selected ? getSelectedDistance() : null}
              onChange={setSelectedDistance}
              error=''
              className='text-gray-5 text-sm font-normal'
              classNamePrefix=''
              formatOptionLabel={''}
              instanceId='distance'
              isMulti={false}
              isDisabled={false}
              placeholder=''
              options={MAP_RADIAL_OPTIONS}
              icon=''
              isClearable
            />
          </div>
        </div>

        <div className='overflow-auto'>
          <MapPlusSidebar isModal='true' />
        </div>
      </div>
    </APIProvider>
  );
};

export default MapComponentStores;
