import { FC, useEffect, useMemo, useState, useRef } from 'react';
import { VideoCardButton } from '../landing/LandingVideoCard';
import { MuteUnmuteButton } from './MuteUnmuteButton';
import { useIntersectionObserver } from '@/utils/useIntersectionObserver';
import './VideoCard.scss';

export const MRVideo: FC<{
  varianbutton?: string;
  play?: boolean;
  loop?: boolean;
  url: string;
  id: string;
  size: string;
  allowUnmute?: boolean;
  autoPlayOnView?: boolean;
}> = ({
  url,
  play = false,
  loop = false,
  varianbutton,
  id,
  size = 'lg',
  allowUnmute = false,
  autoPlayOnView = true,
}) => {
  const [isPlay, setIsPlay] = useState(play);
  const [isMuted, setIsMuted] = useState(true);
  const videoRef = useRef<HTMLVideoElement>(null);
  const [showControls, setShowControls] = useState(false);
  const controlsTimeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);
  const manuallyPaused = useRef(false);

  const containerRef = useIntersectionObserver({
    onIntersect: () => {
      if (
        autoPlayOnView &&
        videoRef.current?.paused &&
        !manuallyPaused.current
      ) {
        videoRef.current
          .play()
          .then(() => {
            setIsPlay(true);
          })
          .catch((error) => console.error('Error auto-playing:', error));
      }
    },
    onLeave: () => {
      if (autoPlayOnView && videoRef.current && !videoRef.current.paused) {
        videoRef.current.pause();
        setIsPlay(false);
        manuallyPaused.current = false;
      }
    },
    threshold: 0.5,
  });

  const sizeCard = useMemo(() => {
    let styleCard = '';
    let styleVid = '';
    switch (size) {
      case 'sm':
        styleCard = 'rounded-t-3xl';
        styleVid = 'rounded-t-3xl';
        break;
      case 'md':
        styleCard = 'aspect-square bg-[#F4F6F8] rounded-[20px] gap-12';
        styleVid = 'h-full object-center';
        break;
      default:
        styleCard = 'gap-y-6';
        styleVid = 'rounded-3xl w-full';
        break;
    }
    return { styleCard, styleVid };
  }, [size]);

  const togglePlay = async () => {
    const vid = videoRef.current;
    if (!vid) return;

    try {
      if (isPlay) {
        vid.pause();
        setIsPlay(false);
        manuallyPaused.current = true;
      } else {
        await vid.play();
        setIsPlay(true);
        manuallyPaused.current = false;
      }
    } catch (error) {
      console.error('Error toggling video:', error);
      setIsPlay(false);
    }
  };

  useEffect(() => {
    const vid = videoRef.current;
    if (!vid) return;

    const handleVideoEnd = () => {
      if (!loop) {
        setIsPlay(false);
      }
    };

    vid.addEventListener('ended', handleVideoEnd);
    vid.loop = loop;
    vid.muted = isMuted;

    return () => {
      vid.removeEventListener('ended', handleVideoEnd);
    };
  }, [loop, isMuted]);

  const toggleMute = () => {
    if (videoRef.current) {
      videoRef.current.muted = !videoRef.current.muted;
      setIsMuted(videoRef.current.muted);
    }
  };

  const handleMouseEnter = () => {
    setShowControls(true);
    if (controlsTimeoutRef.current) {
      clearTimeout(controlsTimeoutRef.current);
      controlsTimeoutRef.current = null;
    }
  };
  const handleMouseLeave = () => setShowControls(false);

  return (
    <div
      ref={containerRef}
      className={`mr-video-container relative flex w-full flex-col items-center justify-start ${sizeCard.styleCard}`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div
        className={`mr-video-wrapper relative w-full overflow-hidden ${sizeCard.styleVid} aspect-square cursor-pointer lg:aspect-video`}
        onClick={togglePlay}
      >
        <video
          ref={videoRef}
          src={url}
          className='mr-video h-full w-full object-cover'
          id={id}
          loop={loop}
          playsInline
          muted={isMuted}
        />
      </div>
      <div
        className={`mr-video-controls absolute bottom-0 right-0 z-[2] flex flex-row items-center justify-end gap-3 p-6 transition-opacity duration-300 ${
          showControls ? 'opacity-100' : 'opacity-0'
        }`}
      >
        <VideoCardButton
          varian={varianbutton}
          isPlaying={isPlay}
          onClick={togglePlay}
        />

        {allowUnmute && (
          <MuteUnmuteButton
            isMuted={isMuted}
            onToggle={toggleMute}
            variant='blue'
          />
        )}
      </div>
    </div>
  );
};
