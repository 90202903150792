'use client';

import Skeleton from 'react-loading-skeleton';

const ProductPageSkeleton = () => {
  return (
    <section className='relative min-h-screen w-full py-24'>
      <div className='container mx-auto flex flex-col items-stretch justify-start gap-y-16 px-5'>
        <div className='mx-auto flex w-full flex-col items-center justify-center gap-y-9 md:w-10/12'>
          <Skeleton
            containerClassName='w-full flex flex-col items-stretch justify-start relative'
            className='block h-12 w-full'
          />
          <div className='mx-auto flex w-full flex-row items-center justify-center gap-4 sm:w-8/12 md:w-6/12'>
            <Skeleton
              containerClassName='w-full flex flex-col items-stretch justify-start relative'
              className='block h-12 w-full'
            />
          </div>
          <div className='mx-auto flex w-full flex-col items-stretch justify-start gap-y-16 md:w-10/12'>
            <div className='mx-auto grid w-full grid-cols-1 gap-9 sm:grid-cols-2 md:w-8/12'>
              <Skeleton
                containerClassName='block relative aspect-square'
                className='h-full w-full'
              />
              <Skeleton
                containerClassName='block relative aspect-square'
                className='h-full w-full'
              />
            </div>
          </div>
          <div className='w-full'>
            <Skeleton
              containerClassName='flex flex-col items-stretch justify-start gap-y-1 flex-1 min-w-full'
              className='h-28 w-full'
              count={5}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default ProductPageSkeleton;
