'use client';

import { FC, ReactNode, useEffect, useState } from 'react';
import { IoPauseSharp, IoReload } from 'react-icons/io5';

import Heading from '@/components/common/Heading';
import Text from '@/components/common/Text';
import { twMerge } from 'tailwind-merge';

export const VideoCardButton: FC<{
  isPlaying?: boolean;
  children?: ReactNode;
  varian?: string;
  [key: string]: any;
}> = ({ isPlaying = false, children, varian = 'blue', ...props }) => {
  let color = '';
  switch (varian) {
    case 'blue':
      color = 'bg-primary text-white';
      break;
    case 'white':
      color = 'bg-white text-black';
      break;
    default:
      break;
  }
  return (
    <button
      type='button'
      className={twMerge(
        'flex h-11 w-11 flex-row items-center justify-center rounded-full',
        color ? color : ''
      )}
      {...props}
    >
      {isPlaying ? (
        <IoPauseSharp className='text-xl' />
      ) : (
        <IoReload className='text-xl' />
      )}
    </button>
  );
};

export const VideoCard: FC<{
  title?: string;
  description?: string;
  image?: string;
  withButton?: boolean;
  children?: ReactNode;
  [key: string]: any;
}> = ({ title, description, image, withButton = true, children, ...props }) => {
  const [isPlaying, setIsPlaying] = useState<boolean>(false);

  const handleClick = () => {
    setIsPlaying(!isPlaying);
  };

  useEffect(() => {
    if (props.renderGsap) {
      props.renderGsap();
    }
  }, []);
  return (
    <div
      className={`mrc-image-video-button relative flex w-full flex-col items-center justify-center rounded-3xl bg-[#EFF4FA] ${props?.renderGsap && 'card-fade-on-scroll'}`}
      {...props}
    >
      <div className='mx-auto flex w-8/12 flex-col items-center justify-center py-12 text-center'>
        {title && <Heading size='4'>{title}</Heading>}
        {description && (
          <Text size='paragraph' className='mt-2'>
            {description}
          </Text>
        )}
      </div>
      <div className='aspect-[13/10] h-auto w-full bg-transparent'>
        {image ? (
          <img
            src={image}
            alt={title}
            title={title}
            className='h-full w-full object-cover'
          />
        ) : (
          <p className='flex h-full w-full flex-row items-center justify-center'>
            &nbsp;
          </p>
        )}
      </div>
      {withButton && (
        <div className='absolute bottom-0 left-0 right-0 z-[2] flex w-full flex-row items-center justify-end p-6'>
          <VideoCardButton isPlaying={isPlaying} onClick={handleClick} />
        </div>
      )}
    </div>
  );
};
