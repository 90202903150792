import { useEffect, useRef } from 'react';

interface UseIntersectionObserverProps {
  onIntersect?: () => void;
  onLeave?: () => void;
  threshold?: number;
  rootMargin?: string;
}

export const useIntersectionObserver = ({
  onIntersect,
  onLeave,
  threshold = 0.5,
  rootMargin = '0px',
}: UseIntersectionObserverProps) => {
  const elementRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleIntersect = () => {
      onIntersect?.();
    };

    const handleLeave = () => {
      onLeave?.();
    };

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            handleIntersect();
          } else {
            handleLeave();
          }
        });
      },
      {
        threshold,
        rootMargin,
      }
    );

    const element = elementRef.current;
    if (element) {
      observer.observe(element);
    }

    return () => {
      if (element) {
        observer.unobserve(element);
      }
    };
  }, [onIntersect, onLeave, threshold, rootMargin]);

  return elementRef;
};
