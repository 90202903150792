import React from 'react';
import { IoVolumeMuteSharp, IoVolumeOffSharp } from 'react-icons/io5';

interface MuteUnmuteButtonProps {
  isMuted: boolean;
  onToggle: () => void;
  variant?: 'blue' | 'white';
}

const variantStyles = {
  blue: 'bg-primary text-white',
  white: 'bg-white text-black',
};

export const MuteUnmuteButton: React.FC<MuteUnmuteButtonProps> = ({
  isMuted,
  onToggle,
  variant = 'blue',
}) => {
  const color = variantStyles[variant] || '';

  return (
    <button
      type='button'
      onClick={onToggle}
      className={`flex h-11 w-11 flex-row items-center justify-center rounded-full ${color}`}
    >
      {isMuted ? (
        <IoVolumeMuteSharp className='text-xl' />
      ) : (
        <IoVolumeOffSharp className='text-xl' />
      )}
    </button>
  );
};
