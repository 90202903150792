import { create } from 'zustand';
import AxiosInstance, { AxiosRequestConfig } from 'axios';
import { PurchaseProps, Meta } from '../types';

interface PurchaseState {
  purchaseComponent: PurchaseProps[] | any[];
  getPurchases: (params: any) => Promise<void>;
  isLoading: boolean;
  isError: boolean;
  meta: Meta;
}

const axios = AxiosInstance.create({
  baseURL: process.env.API_ENDPOINT,
});

export const prefix = '/list-stores?populate=*';

export const usePurchases = create<PurchaseState>((set) => ({
  purchaseComponent: [],
  isLoading: false,
  isError: false,
  meta: {
    pagination: {
      page: 1,
      pageSize: 10,
      pageCount: 1,
      total: 0,
    },
  },
  getPurchases: async (params?: AxiosRequestConfig) => {
    set({ isLoading: true });
    try {
      const response = await axios.get(prefix, params);
      set({ purchaseComponent: response.data.data });
      set({ isLoading: false });
      set({ isError: false });
    } catch (error) {
      console.error('Error fetching purchase:', error);
      set({ isLoading: false });
      set({ isError: true });
    }
  },
}));
