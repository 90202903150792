import { FC, useEffect } from 'react';
import { MdClose } from 'react-icons/md';
import ModalComponent from 'components/modal/ModalComponent';
import MapComponentStores from '../MapComponentStores';
import { usePurchases } from '@/stores/support-page/purchase';

interface ModalMapProps {
  setMapOpen: (value: boolean) => void;
  mapOpen: boolean;
}

const ModalMap: FC<ModalMapProps> = ({ setMapOpen, mapOpen }) => {
  const { purchaseComponent, getPurchases } = usePurchases();

  useEffect(() => {
    getPurchases({ params: '' });
  }, [getPurchases]);

  return (
    <ModalComponent
      containerClass='mb-10h-[calc(100vh-14rem)]'
      size='2xl'
      isOpen={mapOpen}
      close={() => setMapOpen(false)}
    >
      <div className='flex flex-col items-end'>
        <div className='flex justify-end rounded border-none px-6 py-4'>
          <button
            className='flex items-center justify-center rounded-lg p-1'
            onClick={() => setMapOpen(false)}
          >
            <MdClose className='size-4 text-inherit' />
          </button>
        </div>
        <div className='flex w-full justify-between border p-3'>
          {purchaseComponent &&
            purchaseComponent.map((data, idx) => {
              const thumbnail = data.attributes?.thumbnail;
              const retailerName = data.attributes?.name || 'Retailer';
              const retailerId = `modal-retailer-${data.attributes?.name.replace(/\s/g, '-').toLowerCase() ?? data.id}`;

              return (
                <div key={`OL-${idx}`} className='w-[100px]'>
                  <a
                    id={retailerId}
                    href={data.attributes.link}
                    target='_blank'
                    rel='noopener noreferrer'
                    className='block hover:cursor-pointer'
                    onClick={(e) => {
                      try {
                        e.preventDefault();

                        const gtmEvent = {
                          event: 'gtm.linkClick',
                          'gtm.element': e.currentTarget,
                          'gtm.elementUrl': data.attributes.link,
                          'gtm.elementId': retailerId,
                          'gtm.elementClasses': e.currentTarget.className,
                          'gtm.elementTarget': '_blank',
                          'gtm.triggers':
                            '30611115_37,30611115_38,30611115_39,30611115_40,30611115_41,30611115_42,30611115_43,30611115_44,30611115_45,30611115_46',
                          'gtm.handled': true,
                        };

                        if (window.dataLayer) {
                          window.dataLayer.push(gtmEvent);
                        }

                        setTimeout(() => {
                          window.open(data.attributes.link, '_blank');
                        }, 100);

                        e.stopPropagation();
                      } catch (error) {
                        window.open(data.attributes.link, '_blank');
                      }
                    }}
                  >
                    <img
                      className='h-auto w-auto duration-300 hover:scale-110'
                      src={thumbnail?.data?.attributes.url}
                      alt={`${retailerName} logo`}
                    />
                  </a>
                </div>
              );
            })}
        </div>
        <MapComponentStores />
      </div>
    </ModalComponent>
  );
};

export default ModalMap;
